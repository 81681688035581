.header {
  background-color: $primary-color;
  text-align: center;
  border: 1px solid $primary-color;

  &__logo {
    background-image: url("/assets/img/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 90%;
    height: 150px;
    margin: 0 auto;
  }

  &__phones {

  }

  &__single-phone {
    display: block;
    color: white;
    margin-bottom: 10px;
    text-decoration: none;
  }

  @media #{$md} {
    display: flex;
    justify-content: center;

    &__wrapper {
      width: 1024px;
      display: flex;
      justify-content: space-between;
      padding-left: $global-padding;
      padding-right: $global-padding;
    }

    &__logo {
      width: (1024px / 3);
      height: 125px;
      margin: 0;
    }

    &__phones {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

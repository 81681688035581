.slider {

  &__slide {
    height: 350px;
    background-size: cover;
    position: relative;

    @media #{$md} {
      height: 580px;
    }

    &--first {
      background-image: url("/assets/img/image0.jpg");
    }

    &--second {
      background-image: url("/assets/img/image1.jpg");
    }

    &--third {
      background-image: url("/assets/img/image2.jpg");
    }

    &--fourth {
      background-image: url("/assets/img/image3.jpg");
    }

    &--fifth {
      background-image: url("/assets/img/image4.jpg");
    }
  }

  &__header {
    position: absolute;
    bottom: 80px;
    left: 25px;


    color: $secondary-font-color;
    text-shadow: 1px 1px 2px black;
    margin: 0;
    font-size: responsive 24px 48px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 15px;

    @media #{$md} {
      bottom: 170px;
      left: 70px;
      text-shadow: 5px 5px 8px rgba(0, 0, 0, 0.7);
      font-size: 72px;
    }
  }

  &__more {
    position: absolute;
    bottom: 25px;
    left: 25px;
    margin: 15px 0;
    background-color: $active-color;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    width: 140px;
    text-align: center;
    padding: 8px;
    text-decoration: none;
    color: $secondary-font-color;
    font-size: 12px;
    text-transform: uppercase;

    @media #{$md} {
      bottom: 100px;
      left: 70px;
      font-size: 18px;
      width: 180px;
    }

    &:hover {
      background-color: lighten($active-color, 15%);
    }
  }
}

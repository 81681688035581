.hero {
  color: $secondary-font-color;
  background-color: $active-color;
  padding: 20px;
  text-align: center;
  background-image: url("/assets/img/pattern.png");

  &__header {
    text-align: center;
    margin-bottom: 40px;
  }

  &__button {
    display: inline-block;
    background-color: lighten($secondary-color, 20%);
    color: $secondary-font-color;
    border-radius: 5px;
    padding: 25px 30px;
    margin-bottom: 20px;
    font-size: 1.1em;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    transition: background-color .3s;

    &:hover {
      background-color: lighten($secondary-color, 30%);
      transition: background-color .6s;
    }

    &:active, &:visited {

    }
  }
}

@media #{$md} {
  .container {
    background-color: desaturate($primary-color, 75%);
    background-image: url("/assets/img/pattern.png");
  }

  .wrapper {
    margin: 0 auto;
    width: 1024px;
    background-color: white;
  }
}

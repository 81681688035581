.content {
  padding: 12px 20px;
  color: $primary-font-color;

  table {
    color: #333;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
  }

  td, th {
    border: 1px solid transparent;
    transition: all 0.3s;
    padding: 8px;
  }

  th {
    background: #dfdfdf;
    font-weight: bold;
  }

  td {
    background: #fafafa;
    text-align: center;
  }

  tr:nth-child(even) td {
    background: #f1f1f1;
  }

  tr:nth-child(odd) td {
    background: #fefefe;
  }

  tr td:hover {
    background: #666;
    color: #fff;
  }

  li {
    margin-bottom: 10px;
  }

  a {
    color: $primary-color;

    &:hover {
      color: lighten($primary-color, 15%);
    }

    &:active {
      color: $active-color;
    }

    &:visited {
      color: $secondary-color;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;

    &--right {
      @media #{$md} {
        display: block;
        margin: 0 15px;
        float: right;

        &::after {
          clear: right;
        }
      }
    }
  }

  &__large-table {
    overflow-x: scroll;

    @media #{$md} {
      overflow-x: hidden;
    }
  }

  &__vehicle {
    text-align: center;

    @media #{$md} {
      display: flex;
    }
  }

  &__lightbox {
    border: 1px solid #dfdfdf;
    background-color: lighten(#dfdfdf, 10%);
    padding: 10px;
    margin: 0;
    margin-bottom: 15px;
    display: inline-block;

    @media #{$md} {
      margin-right: 12px;
    }
  }
}

//
//   ####    ##   #       ####  #    # #        ##   #####  ####  #####
//  #    #  #  #  #      #    # #    # #       #  #    #   #    # #    #
//  #      #    # #      #      #    # #      #    #   #   #    # #    #
//  #      ###### #      #      #    # #      ######   #   #    # #####
//  #    # #    # #      #    # #    # #      #    #   #   #    # #   #
//   ####  #    # ######  ####   ####  ###### #    #   #    ####  #    #
//

.calculator {
  &__container {
  }

  &__section {
    overflow-x: scroll;
    background-color: #efefef;
    padding: 15px;
  }

  &__note {
    font-size: 14px;
    font-style: italic;
  }

  &__sub-header {
    font-size: 18px;
  }

  &__volume {
    font-size: 16px;
    padding: 8px;
    margin-right: 8px;
  }

  &__volume-error {
    font-size: 12px;
    padding-left: 10px;
    color: red;
  }

  &__address {
    font-size: 16px;
    padding: 8px;
    margin-right: 8px;
    width: 70%;
    min-width: 250px;
  }

  &__button {
    padding: 8px 20px;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    font-size: 18px;
    color: white;
    border-radius: 8px;

    &:hover {
      $hover-color: lighten($primary-color, 10%);
      background-color: $hover-color;
      border-color: $hover-color;
    }

    &:active {
      background-color: $active-color;
      border-color: $active-color;
    }

    &:disabled {
      background-color: darkgrey;
      border-color: darkgrey;
      color: grey;
    }
  }
}

//
//  ##### #####  #    #  ####  #    #        ####  ###### #      ######  ####  #####  ####  #####
//    #   #    # #    # #    # #   #        #      #      #      #      #    #   #   #    # #    #
//    #   #    # #    # #      ####   #####  ####  #####  #      #####  #        #   #    # #    #
//    #   #####  #    # #      #  #              # #      #      #      #        #   #    # #####
//    #   #   #  #    # #    # #   #        #    # #      #      #      #    #   #   #    # #   #
//    #   #    #  ####   ####  #    #        ####  ###### ###### ######  ####    #    ####  #    #
//

.truck-selector {
}

//
//  ##### #####  #    #  ####  #    #       #####  #    # ##### #####  ####  #    #
//    #   #    # #    # #    # #   #        #    # #    #   #     #   #    # ##   #
//    #   #    # #    # #      ####   ##### #####  #    #   #     #   #    # # #  #
//    #   #####  #    # #      #  #         #    # #    #   #     #   #    # #  # #
//    #   #   #  #    # #    # #   #        #    # #    #   #     #   #    # #   ##
//    #   #    #  ####   ####  #    #       #####   ####    #     #    ####  #    #
//

.truck-button {
  position: relative;
  display: block;
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 18px;
  height: 70px;
  width: 410px;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
  border: none;
  background-image: url('/assets/img/truck-types.png');
  background-repeat: no-repeat;

  &--type0 {
    background-position-y: -1px;

    &:hover {
      color: blue;
    }
  }

  &--type0-active {
    color: white;
    background-position-y: -211px;
  }

  &--type1 {
    background-position-y: -74px;

    &:hover {
      color: blue;
    }
  }

  &--type1-active {
    color: white;
    background-position-y: -284px;
  }

  &--type2 {
    background-position-y: -145px;

    &:hover {
      color: blue;
    }
  }

  &--type2-active {
    color: white;
    background-position-y: -355px;
  }

  &__volume {
    position: absolute;
    bottom: 16px;
    left: 12px;
  }

  &__label {
    font-size: 12px;
    text-align: center;
    height: 39px;
    font-weight: normal;
    position: absolute;
    bottom: 8px;
    left: 70px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//
//  #    #   ##   ##### ###### #####  #   ##   #             ####  ###### #      ######  ####  #####  ####  #####        #####  #        ##    ####  ###### #    #  ####  #      #####  ###### #####
//  ##  ##  #  #    #   #      #    # #  #  #  #            #      #      #      #      #    #   #   #    # #    #       #    # #       #  #  #    # #      #    # #    # #      #    # #      #    #
//  # ## # #    #   #   #####  #    # # #    # #      #####  ####  #####  #      #####  #        #   #    # #    # ##### #    # #      #    # #      #####  ###### #    # #      #    # #####  #    #
//  #    # ######   #   #      #####  # ###### #                 # #      #      #      #        #   #    # #####        #####  #      ###### #      #      #    # #    # #      #    # #      #####
//  #    # #    #   #   #      #   #  # #    # #            #    # #      #      #      #    #   #   #    # #   #        #      #      #    # #    # #      #    # #    # #      #    # #      #   #
//  #    # #    #   #   ###### #    # # #    # ######        ####  ###### ###### ######  ####    #    ####  #    #       #      ###### #    #  ####  ###### #    #  ####  ###### #####  ###### #    #
//

.material-selector-placeholder {
  border-radius: 8px;
  background-color: #fdfdfd;
  height: 255px;
  display: flex;
  justify-content: center;
  align-items: center;
}

//
//  #    #   ##   ##### ###### #####  #   ##   #             ####  ###### #      ######  ####  #####  ####  #####
//  ##  ##  #  #    #   #      #    # #  #  #  #            #      #      #      #      #    #   #   #    # #    #
//  # ## # #    #   #   #####  #    # # #    # #      #####  ####  #####  #      #####  #        #   #    # #    #
//  #    # ######   #   #      #####  # ###### #                 # #      #      #      #        #   #    # #####
//  #    # #    #   #   #      #   #  # #    # #            #    # #      #      #      #    #   #   #    # #   #
//  #    # #    #   #   ###### #    # # #    # ######        ####  ###### ###### ######  ####    #    ####  #    #
//

.material-selector {
  border: 1px solid #333;
  border-radius: 8px;
  overflow: hidden;

  &__tab-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__tab {
    display: flex;
    flex: 1;
    border: none;
    background-color: #333;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      background-color: lighten(#333, 15%);
    }

    &--active {
      background-color: $active-color;

      &:hover {
        background-color: lighten($active-color, 15%);
      }
    }
  }

  &__options {
    height: 200px;
    overflow-y: scroll;
    padding: 8px 12px 0 12px;
  }

  &__option {
    margin-bottom: 15px;
    display: block;
    cursor: pointer;
    position: relative;
    padding-left: 22px;

    & input[type=radio] {
      display: none;
    }
  }

  &__circle {
    border: 3px solid $primary-color;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 0;
    background-color: transparent;
    transition: all .3s;

    input[type=radio]:checked ~ & {
      border-color: $active-color;
      background-color: $active-color;
    }
  }
}

//
//   ####  #       ####  #####    ##   #             ####  #####  # #    # #    # ###### #####
//  #    # #      #    # #    #  #  #  #            #      #    # # ##   # ##   # #      #    #
//  #      #      #    # #####  #    # #      #####  ####  #    # # # #  # # #  # #####  #    #
//  #  ### #      #    # #    # ###### #                 # #####  # #  # # #  # # #      #####
//  #    # #      #    # #    # #    # #            #    # #      # #   ## #   ## #      #   #
//   ####  ######  ####  #####  #    # ######        ####  #      # #    # #    # ###### #    #
//

.global-spinner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  z-index: 20000;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &--shown {
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.6);
    transition: background-color 250ms ease-in;
  }

  &__spinner {
    display: none;
  }

  &--shown &__spinner {
    display: block;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    border: 3px solid #ddd;
    border-radius: 50%;
    border-top-color: lighten($active-color, 10%);
  }
}

//
//  ###### ###### #####  ####  #    # # #    #  ####        # #    # #####  #  ####    ##   #####  ####  #####
//  #      #        #   #    # #    # # ##   # #    #       # ##   # #    # # #    #  #  #    #   #    # #    #
//  #####  #####    #   #      ###### # # #  # #      ##### # # #  # #    # # #      #    #   #   #    # #    #
//  #      #        #   #      #    # # #  # # #  ###       # #  # # #    # # #      ######   #   #    # #####
//  #      #        #   #    # #    # # #   ## #    #       # #   ## #    # # #    # #    #   #   #    # #   #
//  #      ######   #    ####  #    # # #    #  ####        # #    # #####  #  ####  #    #   #    ####  #    #
//

.fetching-indicator {
  border: 3px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border-top-color: lighten($active-color, 10%);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

//
//   ####    ##   #       ####  #    # #        ##   #####  ####  #####        #    #  ####  #####    ##   #
//  #    #  #  #  #      #    # #    # #       #  #    #   #    # #    #       ##  ## #    # #    #  #  #  #
//  #      #    # #      #      #    # #      #    #   #   #    # #    # ##### # ## # #    # #    # #    # #
//  #      ###### #      #      #    # #      ######   #   #    # #####        #    # #    # #    # ###### #
//  #    # #    # #      #    # #    # #      #    #   #   #    # #   #        #    # #    # #    # #    # #
//   ####  #    # ######  ####   ####  ###### #    #   #    ####  #    #       #    #  ####  #####  #    # ######
//

.calculator-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: background-color .4s ease-in;
  pointer-events: auto;

  &--hidden {
    background-color: rgba(0, 0, 0, 0);

    transition: background-color .8s ease-out;
    pointer-events: none;
  }

  &__form {
    width: 440px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    transform: translateY(0);
    transition: transform 600ms ease-out;
  }

  &--hidden &__form {
    transform: translateY(-500%);
    transition: transform 600ms ease-in;
  }

  &__header {
    font-size: 24px;
    color: white;
    background-color: $primary-color;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
  }

  &__close {
    cursor: pointer;
    transition: transform .2s ease-in;

    &:hover {
      color: $active-color;
    }

    &:active {
      transform: scale(0.85);
      transition: transform .2s ease-out;
    }
  }

  &__content {
    padding: 12px 24px;
    display: flex;
    flex-direction: column;

    & label {
      margin-bottom: 6px;
    }

    & input {
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__tooltip {
    font-size: 12px;
    color: lighten(black, 30%);
    font-style: italic;
  }
}

.footer {
  padding: 12px 20px;
  padding-top: 30px;
  background-color: $secondary-bg-color;
  color: $secondary-font-color;

  &__logo {
    margin-bottom: 20px;

    h4 {
      font-size: 48px;
      margin: 0;
      text-transform:uppercase;
      font-style: italic;
    }

    span {
      font-size: 12px;
      margin: 0;
      text-transform: uppercase;
      font-style: italic;
      font-family: serif;
    }
  }

  &__contacts {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.8em;
  }

  &__address {
    color: $secondary-font-color;
    display: block;
  }

  &__phone {
    color: $secondary-font-color;
    text-decoration: none;
    display: block;
  }

  &__email {
    color: $secondary-font-color;
    text-decoration: none;
    display: block;
  }

  &__copyright {
    margin-bottom: 20px;
    font-size: 14px;
  }

  @media #{$md} {
    display: flex;
    justify-content: center;

    &__wrapper {
      width: 1024px;
      display: flex;
      flex-wrap: wrap;
      padding-left: $global-padding;
      padding-right: $global-padding;
    }

    &__logo {
      flex: 2;

      h4 {
        font-size: 60px;
      }

      span {
        font-size: 16px;
      }
    }

    &__contacts {

    }

    &__copyright {
      width: 100%;
    }
  }
}

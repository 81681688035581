.menu {
  background-color: $secondary-color;
  color: $secondary-font-color;

  &__layout {
    min-height: 65px;
    padding-top: 10px;
  }

  &__button {
    border: 1px solid $primary-color;
    background-color: $primary-color;
    border-radius: 5px;
    font-size: 24px;
    padding: 8px 12px;
    color: $secondary-font-color;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
    width: 50px;
    text-align: center;
    float: right;
    margin-right: 12px;
  }

  &__wrapper {
    clear: right;
    display: none;
  }

  &__content {
    padding: 12px;
  }

  &__item {
    padding: 8px;
    font-size: 22px;
    color: $secondary-font-color;
    text-decoration: none;
    display: block;

    &:hover {
      color: $active-color;
    }

    &--active {
      background-color: $primary-color;
    }
  }

  @media #{$md} {
    display: flex;
    justify-content: center;

    &__layout {
      width: 1024px;
      min-height: 30px;
      padding: 0;
    }

    &__button {
      display: none;
    }

    &__wrapper {
      display: flex;
      padding: 0;
    }

    &__content {
      display: flex;
      padding: 0;
      width: 100%;
    }

    &__item {
      flex: 1;
      font-size: 16px;
      text-align: center;
      border-right: 1px solid $primary-color;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: $secondary-font-color;
        background-color: darken($primary-color, 15%);
      }

      &--active {
        background-color: $primary-color;
      }

      &:first-of-type {
        border-left: 1px solid $primary-color;
      }
    }
  }
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  min-height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  font-size: 16px;
}

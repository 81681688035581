.materials {

  &__list {
    list-style: none;
    padding: 0;

    & li {

      & a {
        text-decoration: none;
      }
    }
  }

  &__image {
    vertical-align: middle;
    border-radius: 10px;
  }

  &__label {
    margin-left: 15px;
    text-decoration: underline;
    font-weight: bold;
    font-size: 18px;
  }
}

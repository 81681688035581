.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 450px;

  &__label {
    font-size: 16px;
    margin-bottom: 6px;
  }

  input {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  textarea {
    height: 180px;
    margin-bottom: 24px;
  }

  button {
    margin-bottom: 24px;
  }

  &__small {
    font-size: 12px;
    font-style: italic;
    color: lighten(black, 25%);
    margin-bottom: 12px;
  }
}
